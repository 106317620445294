import { animated, useSpring, useTransition } from '@react-spring/web';
import { useMobile } from 'common-nextjs';
import { useRef, useState } from 'react';
import { useInterval } from 'react-use';
import useWindowRefocused from '~/hooks/useWindowRefocused';

const DURATION = 600;

const Arrow = ({ index }: { index: number }) => {
  const mobile = useMobile();

  const props = useSpring({
    // config: config.stiff,
    x: index % 2,
  });

  return (
    <animated.div
      style={{
        width: 0,
        height: 0,
        ...(mobile
          ? {
              borderLeft: '8px solid transparent',
              borderRight: '8px solid transparent',
              borderTop: '14px solid #02c874',
            }
          : {
              borderTop: '14px solid transparent',
              borderBottom: '14px solid transparent',
              borderLeft: '20px solid #02c874',
            }),

        transform: props.x
          .interpolate({
            range: [0, 0.5, 1],
            output: [0, -16, 0],
          })
          .interpolate(x =>
            mobile
              ? `translateY(${x}px) translateX(0px)`
              : `translateY(1px) translateX(${x}px)`,
          ),
      }}
    />
  );
};

const texts = [
  'TO PLAY',
  'TO SAVE',
  'TO EARN',
  'TO CONNECT',
  'TO PAY IT FORWARD',
  'TO SAVE THE PLANET',
  'TO BE THEIR BEST',
  // 'TO GROW THE GAME',
] as const;

const EmpowerAthletes = () => {
  const [index, setIndex] = useState(0);

  const windowFocused = useRef(true);

  useWindowRefocused(
    () => (windowFocused.current = true),
    () => (windowFocused.current = false),
  );

  const textTransitions = useTransition(texts[index], item => ({
    from: {
      position: 'absolute',
      opacity: 0.5,
      transform: 'translateY(48px)',
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0px)',
    },
    leave: {
      opacity: 0,
    },
    // config: config.stiff,
  }));

  useInterval(() => {
    if (windowFocused.current) {
      setIndex(index => (index === texts.length - 1 ? 0 : index + 1));
    }
  }, 2500);

  return (
    <div className="mb-16 mt-4 flex flex-col items-center md:mb-12 md:mt-2 md:flex-row">
      <Arrow index={index} />

      {textTransitions((style, item) => (
        <div key={item} style={style}>
          <div
            className="text-4xl text-white md:ml-8"
            css={`
              @media (max-width: 767px) {
                margin-top: 16px;
              }

              @media (min-width: 767px) {
                font-size: 64px;
              }

              font-weight: 800;
            `}
          >
            {item}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EmpowerAthletes;
