import { CustomLinkProps } from 'common-nextjs';
import Link from 'next/link';
import React from 'react';
import { qsStringify } from 'utils';
import { BaseRouteParams } from '~/typings/custom-next';

interface SellerDashboardLinkOptions {
  page?:
    | 'balance'
    | 'cashout'
    | 'create'
    | 'editContact'
    | 'createBank'
    | 'editBank'
    | 'createCompleted'
    | 'w9'
    | 'documents'
    | 'scanSheets'
    | 'informAct';
}

export function generateSellerDashboardLink({
  page,
  ...options
}: SellerDashboardLinkOptions & BaseRouteParams = {}): string {
  let destination = '/selling';

  switch (page) {
    case 'cashout':
      destination += '/cashout';
      break;

    case 'create':
      destination += '/create';
      break;

    case 'createBank':
      destination += '/create/bank';
      break;
    case 'createCompleted':
      destination += '/create/completed';
      break;

    case 'editContact':
      destination += '/edit/contact';
      break;

    case 'editBank':
      destination += '/edit/bank';
      break;

    case 'w9':
      destination += '/w9';
      break;

    case 'documents':
      destination += '/documents';
      break;

    case 'scanSheets':
      destination += '/scan_sheets';
      break;

    case 'informAct':
      destination += '/inform_act';
      break;
  }

  return destination + qsStringify(options);
}

type Props = CustomLinkProps & SellerDashboardLinkOptions;

const SellerDashboardLink: React.FC<Props> = ({ children, page, ...props }) => {
  const destination = generateSellerDashboardLink({ page });

  return (
    <Link href={destination} prefetch={false} {...props}>
      {children}
    </Link>
  );
};

export default SellerDashboardLink;
