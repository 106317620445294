'use client';

import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { qsStringify } from 'utils';
import { ModalProps } from '~/components/Modal/ModalsGroup';
import { useModals } from '~/contexts/ModalsContext';

import { CustomLinkProps } from 'common-nextjs';

interface RegisterLinkOptions {
  destination?: string;
  referralCode?: string;
  modalProps?: Partial<ModalProps<'loginRegister'>>;
}

type Props = CustomLinkProps & RegisterLinkOptions;

export function generateRegisterLink(options?: RegisterLinkOptions) {
  return `/register${qsStringify(options)}`;
}

export function generateRegisterOnboardingLink(options?: RegisterLinkOptions) {
  return `/register/onboarding${qsStringify(options)}`;
}

const RegisterLink: React.FC<Props> = ({ children, modalProps, ...props }) => {
  const router = useRouter();
  const modals = useModals();

  if (router.route === '/login' || router.route === '/register') {
    return (
      <Link
        href="/register"
        rel="nofollow noindex"
        aria-label="Sign up for SidelineSwap"
        prefetch={false}
        {...props}
      >
        {children}
      </Link>
    );
  }

  return (
    <a
      href="#"
      className={props.className}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        // props.onClick?.(e);
        modals.openModal('loginRegister', {
          defaultView: 'register',
          ...modalProps,
        });
      }}
      aria-label="Sign up for SidelineSwap"
    >
      {children}
    </a>
  );
};

export default RegisterLink;
