'use client';

import React, { useEffect, useRef } from 'react';

export default function ButterWYSIWYGContainer({
  children,
}: {
  children?: React.ReactNode;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.querySelectorAll('video').forEach(video => {
        // Set the volume to 40%
        video.volume = 0.4;

        if (!video.poster || video.poster.endsWith('undefined')) {
          console.log('LOADING VIDEO');
          // Load the first frame
          video.preload = 'metadata';
          video.currentTime = 0.01;
        }
      });
    }
  }, []);

  return <div ref={ref}>{children}</div>;
}
