import { useQuery } from '@tanstack/react-query';
import { fetchFacetItemsNoMeta } from '~/api/items';
import ItemThumbnail from '~/components/ItemThumbnail/ItemThumbnail';
import HorizontalScrollItemGrid from '~/components/legos/HorizontalScrollItemGrid';
import { RailsBlock } from '~/typings/services/rails/tile';

const ItemGridHorizontalScrollBlock = ({ block }: { block: RailsBlock }) => {
  const { data: items } = useQuery(
    ['horizontal-item-block', block.id],
    () => fetchFacetItemsNoMeta(block.query),
    {
      refetchOnMount: false,
    },
  );

  if (!items?.data) {
    return null;
  }

  return (
    <HorizontalScrollItemGrid>
      {items.data.map((item, i) => (
        <ItemThumbnail key={item.id} position={i} preloadImage item={item} />
      ))}
    </HorizontalScrollItemGrid>
  );
};

export default ItemGridHorizontalScrollBlock;
