import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { Button, Input } from 'ui';
import PredictiveResultsList from '~/components/Chrome/Header/PredictiveResultsList';
import usePredictiveSearch from '~/hooks/usePredictiveSearch';
import SearchIcon from '~/public/static/svg/SearchIcon';

type Props = React.HTMLAttributes<HTMLDivElement>;

const CenterSearch: React.FC<Props> = props => {
  const router = useRouter();
  const [query, setQuery] = useState((router.query.q as string) ?? '');
  const [searchOpen, setSearchOpen] = useState(false);
  const [predictiveResults, onSubmit] = usePredictiveSearch(query);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const openSearch = useCallback(() => {
    if (query.length > 1) {
      setSearchOpen(true);
    }
  }, [query]);

  useEffect(() => {
    if (document.activeElement == inputRef.current) {
      openSearch();
    }

    if (query.length === 0) {
      setSearchOpen(false);
    }
  }, [openSearch, query]);

  function closeSearch() {
    setSearchOpen(false);
  }

  useClickAway(containerRef, closeSearch);

  function submitPredictive(event: React.FormEvent, query: string) {
    setSearchOpen(false);
    onSubmit(event, query);
  }

  return (
    <div
      {...props}
      ref={containerRef}
      className="relative mx-4 flex w-1/2"
      style={{
        maxWidth: 714,
      }}
    >
      <form
        action="/search"
        className="flex w-full"
        onSubmit={e => submitPredictive(e, query)}
        role="search"
      >
        <Input
          autoComplete="off"
          className={clsx('rounded-r-none border-r-0', {
            'rounded-bl-none': searchOpen,
            'border-b-0': searchOpen,
          })}
          aria-label="Search for new and used gear"
          placeholder="Search for new and used gear"
          type="search"
          style={{ height: 40 }}
          name="q"
          onChange={e => setQuery(e.target.value)}
          onFocus={openSearch}
          ref={inputRef}
          value={query}
        />

        <Button
          className={clsx('rounded-l-none rounded-r px-4 py-0', {
            'rounded-br-none': searchOpen,
          })}
          aria-label="Submit search"
          color="primary"
          type="submit"
          style={{
            height: 40,
          }}
        >
          <SearchIcon />
        </Button>
      </form>

      {searchOpen && (
        <PredictiveResultsList
          className="border-grey-500 border"
          css={`
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;

            background-color: #fff;
            z-index: 10;
            margin: 0;
            list-style: none;
          `}
          results={predictiveResults}
        />
      )}
    </div>
  );
};

export default CenterSearch;
