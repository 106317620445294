import Link from 'next/link';
import React from 'react';

import { CustomLinkProps } from 'common-nextjs';

type Props = CustomLinkProps;

const MyPurchasesLink: React.FC<Props> = ({ children, ...props }) => (
  <Link href="/purchases" prefetch={false} {...props}>
    {children}
  </Link>
);

export default MyPurchasesLink;
