import Link from 'next/link';
import React from 'react';

import { CustomLinkProps } from 'common-nextjs';

type Props = CustomLinkProps;

const MyFeedLink: React.FC<Props> = ({ children, ...props }) => (
  <Link href="/feed" {...props} rel="nofollow noindex" prefetch={false}>
    {children}
  </Link>
);

export default MyFeedLink;
