import { useMobile } from 'common-nextjs';
import { KnownCookie } from 'common-types';
import { useRouter } from 'next/router';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { useCookie } from 'react-use';
import { getDetailById } from 'utils';
import { fetchFacetMetaNoItems } from '~/api/items';
import { fetchLandersBySlug } from '~/api/landers';
import { reduceEnabledSearchFilters } from '~/api/user';
import { useSavedSearchContext } from '~/contexts/SavedSearchContext';
import { useUserSearchFiltersContext } from '~/contexts/UserSearchFilters';
import { FacetFilteringContext } from '~/contexts/facetFiltering/FacetFilteringContext';
import flattenFilterState from '~/contexts/facetFiltering/flattenFilterState';
import getActiveFacetsCount from '~/contexts/facetFiltering/getActiveFacetsCount';
import shouldForceSearchMode from '~/contexts/facetFiltering/shouldForceSearchMode';
import { fireAppliedFilter } from '~/services/analytics/events/search';
import {
  BaseFacet,
  BaseFacetGroup,
  FilterState,
  FlattenedFilterState,
  TypedRailsFacet,
} from '~/typings/services/rails/facets';
import { RailsCategory } from '~/typings/services/rails/item';
import addToState from '~/utils/addToState';
import deepmergeWithArrays from '~/utils/deepmergeWithArrays';
import filterStateToQueryStringNoCategory from '~/utils/filterStateToLanderQueryString';
import filterStateToQueryString from '~/utils/filterStateToQueryString';
import getTransferableFilterState from '~/utils/getTransferableFilterState';
import getTransferableLanderQueryAndFilterState from '~/utils/getTransferableLanderQueryAndFilterState';
import removeFromState from '~/utils/removeFromState';
import stripLanderQueryFromFilterState from '~/utils/stripLanderQueryFromFilterState';

const strippedProperties = {
  '{_branded}': undefined,
  gclid: undefined,
  fbclid: undefined,
  min_published_at: undefined,
  page: undefined,
  saved_search_id: undefined,
  state: undefined,
  src: undefined,
  stid: undefined,
  track: undefined,
  utm_campaign: undefined,
  utm_content: undefined,
  utm_medium: undefined,
  utm_source: undefined,
};

export default function useFacetFiltering() {
  const router = useRouter();
  const mobile = useMobile();
  const {
    state: {
      filterState,
      facets,
      inModal,
      lander,
      notFoundMetadata,
      paging,
      similarToItem,
      sorts,
      type,
    },
    dispatch,
  } = useContext(FacetFilteringContext);
  const { savedSearch } = useSavedSearchContext();
  const { filters } = useUserSearchFiltersContext();
  const facetUpdateQuery = useRef(filterState);
  const [fastShippingCookie] = useCookie(
    'fast_shipping_filter_enabled' as KnownCookie,
  );
  const [canadianSellersCookie] = useCookie(
    'canadian_sellers_filter_enabled' as KnownCookie,
  );

  const navigateToUpdatedFilterState = useCallback(
    (newState: FlattenedFilterState, replace?: boolean) => {
      const split = router.asPath.split('?');
      let stringified: string;

      const forceSearchMode = shouldForceSearchMode(newState);

      if (type === 'locker') {
        stringified = filterStateToQueryString(
          newState,
          ['seller'],
          sorts?.default_option,
        );
      } else if (type === 'search' || forceSearchMode) {
        stringified = filterStateToQueryString(
          newState,
          [],
          sorts?.default_option,
        );
      } else {
        stringified = filterStateToQueryStringNoCategory(
          newState,
          [],
          sorts?.default_option,
        );
      }

      const navigate = replace ? router.replace : router.push;

      if (forceSearchMode) {
        navigate(`/search${stringified}`, `/search${stringified}`, {
          scroll: mobile,
        });
        return;
      }

      navigate(router.route + stringified, split[0] + stringified, {
        scroll: mobile,
      });
    },
    [router, sorts?.default_option, type],
  );

  const unflattenedFilterState = useMemo(
    () =>
      deepmergeWithArrays([
        lander?.query,
        savedSearch?.query,
        filterState,
        reduceEnabledSearchFilters(
          filters,
          fastShippingCookie,
          canadianSellersCookie,
        ),
      ]) as FilterState,
    [
      filterState,
      filters,
      lander?.query,
      savedSearch?.query,
      fastShippingCookie,
      canadianSellersCookie,
    ],
  );

  const evaluatedFilterState = useMemo(
    () => flattenFilterState(unflattenedFilterState),
    [unflattenedFilterState],
  );

  const isSelected = useCallback(
    (
      facet: BaseFacetGroup | string,
      value?: BaseFacet | string[] | number[] | string | number,
    ): boolean => {
      let filterStateElement: any[];
      let filterStateValue: string | number;

      if (typeof facet === 'string') {
        filterStateElement = evaluatedFilterState[facet];
      } else {
        filterStateElement = evaluatedFilterState[facet.slug];
      }

      if (value == null) {
        if (Array.isArray(filterStateElement)) {
          return filterStateElement.length > 0;
        } else {
          return filterStateElement != null;
        }
      }

      if (typeof value === 'string' || typeof value === 'number') {
        filterStateValue = value;
      } else if (Array.isArray(value)) {
        filterStateValue = value[0];
      } else {
        filterStateValue = value.id;
      }

      if (filterStateElement) {
        if (Array.isArray(filterStateElement)) {
          return !!filterStateElement.find(e => e == filterStateValue);
        } else {
          return filterStateElement == filterStateValue;
        }
      } else {
        return false;
      }
    },
    [evaluatedFilterState],
  );

  const changeFacetSelection = useCallback(
    async (
      facet: BaseFacetGroup | string,
      value: BaseFacet | string[] | number[] | string | number | null,
      singleValue?: boolean,
      navigate = true,
    ) => {
      let newState: FlattenedFilterState = {
        ...filterState,
        ...strippedProperties,
      };

      if (!shouldForceSearchMode(newState)) {
        stripLanderQueryFromFilterState(lander, newState);
      }

      let facetSlug;
      let newValue;

      if (typeof facet === 'string') {
        facetSlug = facet;
      } else {
        facetSlug = facet.slug;
      }

      fireAppliedFilter(facet, value);

      if (value == null) {
        newState = removeFromState(newState, facetSlug);
      } else {
        if (facetSlug === 'category') {
          if (isSelected(facet, value)) {
            newState = removeFromState(newState, facetSlug);
          } else {
            let newCategoryValue;
            if (typeof value === 'string') {
              newCategoryValue = [value];
            } else if (typeof value === 'number') {
              newCategoryValue = [value];
            } else if (Array.isArray(value)) {
              newCategoryValue = value;
            } else {
              newCategoryValue = [value.id];
            }

            newState = {
              ...newState,
              category: newCategoryValue,
            };
          }
        } else {
          if (
            typeof value === 'string' ||
            typeof value === 'number' ||
            Array.isArray(value)
          ) {
            newValue = value;
          } else {
            newValue = singleValue ? value.id : [value.id];
          }

          if (isSelected(facet, value)) {
            newState = removeFromState(newState, facetSlug, newValue);
          } else {
            newState = addToState(newState, facetSlug, newValue);
          }
        }
      }

      facetUpdateQuery.current = newState;
      dispatch({
        type: 'UPDATED_FILTER_STATE',
        payload: { filterState: newState },
      });

      // Lander exists and we're removing a value.
      // We'll check the lander path for the facet and remove that path segment _and_ anything after it
      if (
        lander &&
        typeof value == 'object' &&
        !Array.isArray(value) &&
        value != null
      ) {
        const split = router.asPath.split('?');
        const path = split[0];
        const landerPathSegments = path.split('/');
        const facetPathIndex = landerPathSegments.indexOf(value.slug);

        if (facetPathIndex > -1) {
          const newLanderPathSegments = landerPathSegments.slice(
            0,
            facetPathIndex,
          );

          try {
            const landers = await fetchLandersBySlug(
              newLanderPathSegments.slice(2).join('/'),
            );

            if (!landers || landers.length === 0) {
              throw new Error('no landers found');
            }

            const newPath =
              router.pathname
                .replace('[...shop]', landers[0]?.path)
                .replace('[...lander]', landers[0]?.path) +
              (split[1] ? '?' + split[1] : '');

            router.push(newPath, newPath, {
              scroll: mobile,
            });

            if (inModal) {
              requestFacetUpdate();
            }
            return;
          } catch (e) {
            // Do nothing
          }
        }
      }

      if (navigate) {
        navigateToUpdatedFilterState(newState, false);
      }

      if (inModal) {
        requestFacetUpdate();
      }
    },
    [
      dispatch,
      lander,
      filterState,
      inModal,
      isSelected,
      navigateToUpdatedFilterState,
    ],
  );

  const requestFacetUpdate = useCallback(async () => {
    try {
      const facetMeta = await fetchFacetMetaNoItems(facetUpdateQuery.current);

      dispatch({
        type: 'UPDATED_FACETS',
        payload: {
          facets: facetMeta.facets,
          paging: facetMeta.paging,
        },
      });
    } catch (err) {}
  }, [dispatch]);

  const clearAll = useCallback(() => {
    let newState: any;

    if (type === 'locker') {
      newState = {
        seller: facetUpdateQuery.current.seller,
        sort: facetUpdateQuery.current.sort,
        state: facetUpdateQuery.current.state,
      };
    } else if (lander) {
      newState = lander.query;
    } else {
      newState = {};
    }

    dispatch({
      type: 'CLEAR_ALL',
      payload: {
        filterState: newState,
      },
    });
    facetUpdateQuery.current = newState;
    requestFacetUpdate();
    navigateToUpdatedFilterState(newState);
  }, [
    dispatch,
    lander,
    navigateToUpdatedFilterState,
    requestFacetUpdate,
    type,
  ]);

  const setPrices = useCallback(
    (min?: number, max?: number) => {
      const newState = {
        ...evaluatedFilterState,
        min_price: min,
        max_price: max,
      };

      facetUpdateQuery.current = newState;

      dispatch({
        type: 'UPDATED_FILTER_STATE',
        payload: { filterState: newState },
      });

      navigateToUpdatedFilterState(newState);

      if (inModal) {
        requestFacetUpdate();
      }
    },
    [evaluatedFilterState, navigateToUpdatedFilterState],
  );

  const filterCount = useMemo(
    () => getActiveFacetsCount(evaluatedFilterState, sorts?.default_option),
    [evaluatedFilterState, sorts?.default_option],
  );

  const categories = useMemo(
    () =>
      (facets.find(f => f.slug === 'category')
        ?.children as TypedRailsFacet<RailsCategory>[]) ?? null,
    [facets],
  );

  const category = useMemo(
    () => getDetailById(categories, evaluatedFilterState?.category?.[0]),
    [categories, evaluatedFilterState],
  );

  const transferableFilterState = useMemo(
    () =>
      getTransferableFilterState(
        lander,
        evaluatedFilterState,
        sorts?.default_option,
      ),
    [lander, evaluatedFilterState, sorts?.default_option],
  );

  const transferableLanderQueryAndFilterState = useMemo(
    () =>
      getTransferableLanderQueryAndFilterState(
        lander,
        evaluatedFilterState,
        sorts?.default_option,
      ),
    [lander, evaluatedFilterState, sorts?.default_option],
  );

  return {
    categories,
    category,
    changeFacetSelection,
    clearAll,
    facets,
    filterCount,
    isSelected,
    lander,
    notFoundMetadata,
    paging,
    requestFacetUpdate,
    setPrices,
    similarToItem,
    sorts,
    transferableFilterState,
    transferableLanderQueryAndFilterState,
    type,
    unflattenedFilterState,
    filterState: evaluatedFilterState,
  };
}
