import { useQuery } from '@tanstack/react-query';
import { fetchShapedRecommendedRailsItems } from '~/api/appDir/shapedAi';
import ManagedItemThumbnailGrid from '~/components/ItemGrids/ManagedItemThumbnailGrid';
import { useSession } from '~/contexts/SessionContext';
import { RailsBlock } from '~/typings/services/rails/tile';
import BlockSection from '../BlockSection';

export default function ShapedRecommendedItemsGridBlock({
  block,
  headerType,
}: {
  block: RailsBlock;
  headerType?: 'h1' | 'h2' | 'h3';
}) {
  const session = useSession();
  const { data } = useQuery(
    ['shaped-recommended-items'],
    () => {
      const externalId = session.user?.external_id;
      if (!externalId) {
        return undefined;
      }
      return fetchShapedRecommendedRailsItems(externalId);
    },
    {
      enabled: !!session.user?.external_id,
    },
  );

  if (!data || data.data.length === 0) {
    return null;
  }

  return (
    <BlockSection block={block} headerType={headerType}>
      <ManagedItemThumbnailGrid items={data.data} />
    </BlockSection>
  );
}
