'use client';

import { Transition } from '@headlessui/react';
import React, { useState } from 'react';
import BaseNewModal, {
  BaseNewModalProps,
} from '~/components/NewModal/BaseNewModal';
import NewModalBody, {
  NewModalBodyProps,
} from '~/components/NewModal/NewModalBody';
import { NewModalContext } from '~/contexts/NewModalContext';

export type ManagedModalProps = Omit<NewModalBodyProps, 'children'> &
  Pick<BaseNewModalProps, 'className' | 'style' | 'size' | 'initialFocus'> & {
    children:
      | React.ReactNode
      | ((onRequestClose: () => void) => React.ReactNode);
    trigger:
      | React.ReactNode
      | ((handleOpen: () => void, isOpen: boolean) => React.ReactNode);
  };

const ManagedModal: React.FC<ManagedModalProps> = ({
  children,
  trigger,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  let renderedTrigger: React.ReactNode;
  if (typeof trigger === 'function') {
    renderedTrigger = trigger(() => setOpen(true), open);
  } else {
    renderedTrigger = <span onClick={() => setOpen(true)}>{trigger}</span>;
  }

  let renderedChildren: React.ReactNode;
  if (typeof children === 'function') {
    renderedChildren = children(onClose);
  } else {
    renderedChildren = children;
  }

  return (
    <>
      {renderedTrigger}
      <Transition show={open} as={React.Fragment}>
        <BaseNewModal onClose={onClose} open={open} {...props}>
          <NewModalContext value={{ onClose, open }}>
            <NewModalBody {...props}>{renderedChildren}</NewModalBody>
          </NewModalContext>
        </BaseNewModal>
      </Transition>
    </>
  );
};

export default ManagedModal;
