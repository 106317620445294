'use client';

import { clsx } from 'clsx';
import { CustomLinkProps } from 'common-nextjs';
import Link from 'next/link';
import { useSwapPopout } from '~/contexts/SwapPopoutContext';

type Props = CustomLinkProps & {
  noPopout?: boolean;
  swapId: number;
  onClick?: () => void;
};

function SwapDetailsLink({ children, noPopout, ...props }: Props) {
  if (noPopout) {
    return (
      <SwapDetailsLinkNoPopout {...props}>{children}</SwapDetailsLinkNoPopout>
    );
  }

  return <SwapPopoutLink {...props}>{children}</SwapPopoutLink>;
}

function SwapPopoutLink({ children, onClick, swapId, ...props }: Props) {
  const { enabled, openSwap } = useSwapPopout();

  // Use the popout if available
  if (enabled) {
    return (
      <button
        className={clsx(props.className, 'text-left')}
        type="button"
        onClick={e => {
          if (window['nativeBridge']) {
            e.preventDefault();
            e.stopPropagation();

            window['nativeBridge'].viewSwap(swapId);
          } else {
            onClick?.();
            openSwap(swapId);
          }
        }}
      >
        {children}
      </button>
    );
  }

  return (
    <SwapDetailsLinkNoPopout swapId={swapId} noPopout {...props}>
      {children}
    </SwapDetailsLinkNoPopout>
  );
}

function SwapDetailsLinkNoPopout({
  children,
  swapId,
  onClick,
  noPopout,
  ...props
}: Props) {
  return (
    <Link
      href={`/swaps/${swapId}`}
      prefetch={false}
      {...props}
      onClick={e => {
        if (window['nativeBridge']) {
          e.preventDefault();
          e.stopPropagation();

          window['nativeBridge'].viewSwap(swapId);
        } else if (onClick) {
          onClick(e);
        }
      }}
    >
      {children}
    </Link>
  );
}

export default SwapDetailsLink;
