import React from 'react';
import RenderBlock from '~/components/legos/RenderBlock';
import { RailsBlock, RailsPageView } from '~/typings/services/rails/tile';

interface Props {
  allowBlockLayouts?: RailsBlock['layout'][];
  pageView?: RailsPageView | null;
  defaultHasHero?: boolean;
  defaultHasTitleBlock?: boolean;
  ignoreBlockLayouts?: RailsBlock['layout'][];
  topPadding?: boolean;
  noBottomPadding?: boolean;
}

const PageView: React.FC<Props> = ({
  allowBlockLayouts,
  defaultHasHero,
  defaultHasTitleBlock,
  ignoreBlockLayouts,
  pageView,
}) => {
  if (!pageView) {
    return null;
  }

  const hasHero =
    defaultHasHero ||
    !!pageView.blocks.find(
      b =>
        b.layout === 'hero' ||
        b.layout === 'carousel' ||
        b.layout === 'results-grid' ||
        b.layout === 'results',
    );
  const hasTitleBlock =
    defaultHasTitleBlock ||
    !!pageView.blocks.find(
      b => b.layout === 'text-title' || b.layout === 'lander-header',
    );

  const renderedBlocks = pageView.blocks.map((block, i) => {
    if (allowBlockLayouts && !allowBlockLayouts?.includes(block.layout)) {
      return <React.Fragment key={i} />;
    }

    if (ignoreBlockLayouts && ignoreBlockLayouts?.includes(block.layout)) {
      return <React.Fragment key={i} />;
    }

    return (
      <RenderBlock
        key={i}
        block={block}
        pageView={pageView}
        hasHero={hasHero}
        hasTitleBlock={hasTitleBlock}
      />
    );
  });

  if (pageView.blocks.length <= 1) {
    return <div>{renderedBlocks}</div>;
  } else {
    return (
      <div className="space-y-4 md:space-y-8 2xl:space-y-14">
        {renderedBlocks}
      </div>
    );
  }
};

export default PageView;
