import { CustomLinkProps } from 'common-nextjs';
import Link from 'next/link';
import React from 'react';

// const urlRe = /^(?:https:\/\/sidelineswap\.com)?\/?(?:butter_)?blog\/?(.*)/;
const urlRe = /^\/?(?:butter_)?blog\/?(.*)/;
const sportFixRe = /^\w+\/\w+/;

interface ButterBlogLinkArguments {
  author?: string;
  category?: string;
  post?: string;
  tag?: string;
  search?: boolean;
  href?: string; // a link from the CMS
}

export function generateButterBlogLink(args?: ButterBlogLinkArguments): string {
  const { author, category, post, search, tag, href } = args || {};

  let url = '/blog';

  if (href) {
    const match = href.match(urlRe);

    if (match) {
      url += `/${match[1]}`;
    } else {
      const sportFix = href.match(sportFixRe);
      if (sportFix) {
        url += `/sport/${href}`;
      }
    }
  } else if (post) {
    url += `/${post}`;
  } else if (tag && category) {
    url += `/sport/${category}/${tag}`;
  } else if (category) {
    url += `/sport/${category}`;
  } else if (search) {
    url += '/search';
  } else if (author) {
    url += `/author/${author}`;
  }

  return url;
}

interface Props extends ButterBlogLinkArguments, CustomLinkProps {}

const ButterBlogLink: React.FC<Props> = ({ children, ...props }) => {
  const url = generateButterBlogLink(props);

  return (
    <Link prefetch={false} {...props} href={url}>
      {children}
    </Link>
  );
};

export default ButterBlogLink;
