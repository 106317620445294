import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import LoginLink from '~/components/Links/LoginLink';
import { useSession } from '~/contexts/SessionContext';

import { CustomLinkProps } from 'common-nextjs';

type Props = CustomLinkProps & {
  page?: 'purchase' | 'purchaseComplete';
};

const GiftCardLink: React.FC<Props> = ({ children, page, ...props }) => {
  const { loggedIn } = useSession();
  const router = useRouter();

  if (page === 'purchase') {
    if (loggedIn) {
      return (
        <Link
          href="/gift-cards/purchase"
          prefetch={false}
          {...props}
          aria-label="Purchase gift cards here"
        >
          {children}
        </Link>
      );
    } else {
      return (
        <LoginLink
          onSuccessfulLogin={() => router.push('/gift-cards/purchase')}
          message="To purchase gift cards, create a SidelineSwap account"
          aria-label="Purchase gift cards here"
          {...props}
        >
          {children}
        </LoginLink>
      );
    }
  }

  return (
    <Link
      href="/gift-cards"
      prefetch={false}
      {...props}
      aria-label="Buy or redeem gift cards here"
    >
      {children}
    </Link>
  );
};

export default GiftCardLink;
